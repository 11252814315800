import React from "react";
import styled from "styled-components";
import * as ReactScroll from "react-scroll";
import { withPage } from "../Page";
import IntroItem from "../Components/GeneralItem";
import * as L from "../Utils/Lang";
import { StaticFluidImage } from "../Components/StaticImage";

class ProductsPage extends React.Component {
  render() {
    return (
      <Wrapper banner={"images/products-banner.png"}>
        <div className="banner">
          <div className="blur-banner">
            <StaticFluidImage path="products-banner.png" />
          </div>
          <SloganWrapper>
            <Slogan large css={"margin-bottom: 30px;"}>
              {L.s("productsAndServices")}
            </Slogan>
            <Slogan>{L.s("productsSubtitle")}</Slogan>
          </SloganWrapper>
        </div>

        <ReactScroll.Link to="our-services" smooth={true} offset={-140}>
          {/* <ScrollIcon /> */}
        </ReactScroll.Link>

        <IntroWrapper>
          <IntroItem
            itemName="product-1"
            img="images/6.png"
            title={L.s("advantage")}
            description={L.s("advantageContent")}
          />
          <IntroItem
            itemName="product-2"
            img="images/7.png"
            title={L.s("service")}
            description={L.s("serviceContent")}
            reverse
          />
        </IntroWrapper>
      </Wrapper>
    );
  }
}

let Wrapper = styled.div`
  background-color: black;
  & .blur-banner {
    @media screen and (max-width:700px) {
      display: none;
    } 
  }
  & > .banner {
    background-color: lightgray;
    position: relative;
    @media screen and (max-width:700px) {
      height: 100vh;
      background-image: url('${props => props.banner}');
      background-size: cover;
      background-repeat: none;
      background-position: center;
    } 
  }
`;

let Banner = styled.div`
    background-color: #ccc;
    background-image: url('${props => props.img}');
    background-size: cover;
    background-repeat: none;
    background-position: center;
    width: 100vw;
    min-height: 100vh;
    /* top: -90px; */
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media screen and (max-width:700px) {
    }
`;

let SloganWrapper = styled.div`
  position: absolute;
  padding: 10px;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.5);
  top: 50%;
  left: 40%;
  transform: translate(-50%, -50%);
  @media screen and (max-width: 700px) {
    /* position: static;
    transform: none;
    padding: 20px;
    padding-top: 100px; */
    transform: translate(-50%, -45%);
  }
`;

let Slogan = styled.div`
  font-size: ${props => (props.large ? 34 : 16)}px;
  font-weight: ${props => (props.large ? 500 : 400)};
  color: ${props => (props.large ? "#418DB9" : "#fff")};
  line-height: ${props => (props.large ? 42 : 28)}px;
  letter-spacing: 1px;
  text-align: left;

  @media screen and (max-width: 700px) {
    font-size: ${props => (props.large ? 26 : 16)}px;
    line-height: ${props => (props.large ? 30 : 20)}px;
  }
  ${props => props.css}
`;

let IntroWrapper = styled.div``;

export default withPage(ProductsPage);
